import React, { Fragment } from "react";

import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import withRoot from "../withRoot";
import Header from "../component/header";

const WeeklyTechTagsRecap = ({ pageContext, classes }) => {
  const tags = pageContext.tags;
  let totalTags = tags.length;

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Weekly tech read / all tags - Sébastien Viande - Developper FullStack
        </title>
        <meta name="description" content="Personal website, portfolio, blog." />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>
      <Header />
      <Paper id="main" className={classes.main}>
        <Link to="/weekly-tech-read" className={classes.link}>
          <Typography color="primary" align="center" variant="h1" gutterBottom>
            {"Weekly tech read"}
          </Typography>
        </Link>
        <Typography align="center">
          {totalTags} tags created since 2017
        </Typography>
        <Grid container spacing={2} direction="row">
          {tags
            .sort(
              (a, b) =>
                b.childrenWallabagArticles.length -
                a.childrenWallabagArticles.length
            )
            .map((tag) => {
              return (
                <Grid key={tag.ig} item xs={6} md={3} lg={2}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Chip
                        size="small"
                        label={`${tag.name}`}
                        color="primary"
                      />
                      <Typography color="textSecondary">
                        {tag.childrenWallabagArticles.length} articles
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        component={Link}
                        to={`/weekly-tech-read/tags/${tag.name}/`}
                        size="small"
                      >
                        Learn More
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Paper>
    </Fragment>
  );
};

const styles = ({ spacing, breakpoints }) => ({
  main: {
    position: "relative",
    marginTop: "-5rem",
    marginBottom: "5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [breakpoints.up("md")]: {
      marginLeft: "5rem",
      marginRight: "5rem",
    },
    padding: spacing(2),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "none",
  },
});

export default withRoot(withStyles(styles)(WeeklyTechTagsRecap));
